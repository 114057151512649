import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import Img from "../../images/home.png";
import { useLocation, Link } from "react-router-dom";
import styles from "../../styles/Book.module.css";
import Kitchenette from "../../images/icons/kitchenette.svg";
import LivingRooms from "../../images/icons/livingRooms.svg";
import MasterBedroom from "../../images/icons/masterBedroom.svg";
import PowderRoom from "../../images/icons/powderRoom.svg";
import SingleBeds from "../../images/icons/singleBeds.svg";
import Shower from "../../images/icons/shower.svg";
// calendar
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import axios from "axios";

import Similar from "./Similar";
const Room = () => {
  const { pathname } = useLocation();

  const [room, setRoom] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const [data, setData] = useState({
    checkin: null,
    checkout: null,
    nights: 0,
  });
  const getRoom = () => {
    const params = {
      id: pathname.split("room/")[1],
      withGalleryImages: true,
    };

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/room`, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        setRoom(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRoom();
  }, [pathname]);
  const today = new Date();
  const isMobile = useMediaQuery("(max-width: 450px)");
  return (
    <div>
      <img src={Img} />

      <Grid container style={{ marginTop: -250 }}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={styles.roomInfoSection}
        >
          <img
            src={
              selectedImage
                ? `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${selectedImage}`
                : `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${room?.image}`
            }
            alt="image"
            style={{
              width: "100%",
              marginBottom: 10,
              maxHeight: 368,
              objectFit: "cover",
            }}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xl={12 / (room?.images?.length + 1)}
              lg={12 / (room?.images?.length + 1)}
              md={12 / (room?.images?.length + 1)}
              sm={12 / (room?.images?.length + 1)}
              xs={12 / (room?.images?.length + 1)}
            >
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${room?.image}`}
                alt="image"
                style={{ width: "100%" }}
                onClick={() => setSelectedImage(room?.image)}
              />
            </Grid>
            {room?.images?.map((file, index, arr) => (
              <Grid
                key={index}
                item
                xl={12 / (arr.length + 1)}
                lg={12 / (arr.length + 1)}
                md={12 / (arr.length + 1)}
                sm={12 / (arr.length + 1)}
                xs={12 / (arr.length + 1)}
              >
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${file.image}`}
                  alt="image"
                  style={{ width: "100%" }}
                  onClick={() => setSelectedImage(file.image)}
                />
              </Grid>
            ))}
          </Grid>
          {/* <h1 style={{ fontSize: "32px" }}>{room?.name}</h1> */}
          <Grid
            container
            spacing={5}
            style={{ marginTop: 40, paddingBottom: 50 }}
          >
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={LivingRooms} className={styles.optionImage} />
                <span className={styles.optionName}>
                  {room?.living_rooms} LIVING ROOMS
                </span>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={MasterBedroom} className={styles.optionImage} />
                <span className={styles.optionName}>
                  {room?.master_bedroom} MASTER BED ROOM
                </span>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={Kitchenette} className={styles.optionImage} />
                <span className={styles.optionName}>
                  {room?.kitchenette} KITCHENETTE
                </span>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={PowderRoom} className={styles.optionImage} />
                <span className={styles.optionName}>
                  {room?.powder_room} POWDER ROOM
                </span>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={SingleBeds} className={styles.optionImage} />
                <span className={styles.optionName}>
                  {room?.single_beds} SINGLE BEDS
                </span>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <img src={Shower} className={styles.optionImage} />
                <span className={styles.optionName}>{room?.shower} SHOWER</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <div className={styles.CalendarSection}>
            <h1>{room.name}</h1>
            <p>{room.description}</p>
            <DateRange
              calendarWidth={"100%"}
              className={styles.DateRange}
              months={1}
              onChange={(item) => {
                const diffInMs = item.booking.endDate - item.booking.startDate;
                const diffInDays = 1 + diffInMs / (1000 * 60 * 60 * 24);

                setData((prev) => ({
                  ...prev,
                  checkin: item.booking.startDate,
                  checkout: item.booking.endDate,
                  nights: diffInDays,
                }));
              }}
              showMonthAndYearPickers={false}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              classNames={{}}
              color={"rgba(99, 225, 246, 1)"}
              rangeColors={[
                "rgba(1, 116, 207, 1)",
                "rgba(99, 225, 246, 1)",
                "rgba(1, 116, 207, 1)",
              ]}
              ranges={[
                {
                  startDate: data.checkin ? new Date(data.checkin) : today,
                  endDate: data.checkout ? new Date(data.checkout) : today,
                  key: "booking",
                },
              ]}
              minDate={today}
              // direction={window.innerWidth < 800 ? "vertical" : "horizontal"}
            />
            <div className={styles.row}>
              <h2>
                {room?.price} <span>USD/NIGHT</span>
              </h2>

              <Link
                className={styles.book}
                state={
                  data && {
                    from: data.checkin,
                    to: data.checkout,
                    id: pathname.split("room/")[1],
                  }
                }
                to={`/book/${pathname.split("room/")[1]}`}
              >
                BOOK NOW
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      <Similar />
    </div>
  );
};

export default Room;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout";
import Home from "./pages/Home";
import Book from "./pages/Book";
import Rooms from "./pages/Rooms";
import Room from "./pages/Room";
function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/book/:id" element={<Book />} />
          <Route path="/room/:id" element={<Room />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;

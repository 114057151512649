import React, { useState } from "react";
import { Grid } from "@mui/material";
import styles from "../../styles/Home.module.css";
import SwimmingPool from "../../images/icons/swimmingPool.svg";
import KidsPool from "../../images/icons/kidsPool.svg";
import FootballField from "../../images/icons/footballField.svg";
import IndoorJacuzzi from "../../images/icons/indoorJacuzzi.svg";

import useFetch from "../../customHooks/useFetch";

const Services = () => {
  const [active, setActive] = useState(null);

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/services`
  );

  return (
    <div style={{ paddingTop: 100 }}>
      <Grid container>
        {data?.map((service, index) => (
          <Grid
            key={index}
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{ borderLeft: index > 0 ? "0.5px solid #193163" : "none" }}
          >
            <h1
              onClick={() => setActive(service)}
              className={`${styles.serviceTitle} ${
                (!active && index == 0) || service?.id == active?.id
                  ? styles.activeTitle
                  : null
              }`}
            >
              {service.name}
            </h1>
          </Grid>
        ))}
      </Grid>
      <div className={styles.serviceImg}>
        {/* <img
          src={`${
            process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
          }/servicesImages/${active ? active?.image : data && data[0]?.image}`}
        /> */}

        {data?.map((service, index) => (
          <img
            style={{
              display:
                (active && active.id == service.id) || (!active && index == 0)
                  ? ""
                  : "none",
            }}
            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service.image}`}
          />
        ))}
      </div>
    </div>
    // <div>
    //   <Grid container style={{ margin: 20 }}>
    //     <Grid
    //       item
    //       xl={5}
    //       lg={5}
    //       md={5}
    //       sm={5}
    //       xs={12}
    //       style={{
    //         borderRight: "1px solid rgba(11, 70, 193, 1)",
    //         justifyContent: "center",
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <div>
    //         <h1 className={styles.bigTitle}>OUR</h1>
    //         <h1 className={styles.bigTitle}>SERVICES</h1>
    //       </div>
    //     </Grid>
    //     <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
    //       <div>
    //         <div className={styles.row}>
    //           <div className={styles.serviceSection}>
    //             <img
    //               src={SwimmingPool}
    //               style={{ width: "4vw", marginBottom: "20px" }}
    //             />
    //             <h1 className={styles.smallTitle}>SWIMMING</h1>
    //             <h1 className={styles.smallTitle}>POOL</h1>
    //           </div>
    //           <div className={styles.serviceSection}>
    //             <img
    //               src={KidsPool}
    //               style={{ width: "4vw", marginBottom: "20px" }}
    //             />
    //             <h1 className={styles.smallTitle}>KIDS</h1>
    //             <h1 className={styles.smallTitle}>POOL</h1>
    //           </div>
    //         </div>
    //         <div className={styles.row}>
    //           <div className={styles.serviceSection}>
    //             <img
    //               src={FootballField}
    //               style={{ width: "4vw", marginBottom: "20px" }}
    //             />
    //             <h1 className={styles.smallTitle}>FOOTBALL</h1>
    //             <h1 className={styles.smallTitle}>FIELD</h1>
    //           </div>
    //           <div className={styles.serviceSection}>
    //             <img
    //               src={IndoorJacuzzi}
    //               style={{ width: "4vw", marginBottom: "20px" }}
    //             />
    //             <h1 className={styles.smallTitle}>INDOOR</h1>
    //             <h1 className={styles.smallTitle}>JACUZZI</h1>
    //           </div>
    //         </div>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

export default Services;

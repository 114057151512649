import React from "react";
import { Container, Grid } from "@mui/material";
import styles from "../../styles/Home.module.css";

import phone from "../../images/icons/phone.svg";

const Cuisine = ({ title, paragraph, image }) => {
  return (
    <div style={{ margin: "40px 0px 10px 40px" }} id="dining">
      <Grid container style={{ borderLeft: "1px solid #193163" }}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{ padding: "10px !important", margin: 0 }}
        >
          <div>
            <h1 className={styles.bigTitle}>{title?.split(" ")[0]}</h1>
            <h1 className={styles.bigTitle}>{title?.split(" ")[1]}</h1>

            <div
              className={styles.cuisineParagraph}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
            <p className={styles.phoneNumber}>
              <span>
                <img src={phone} />
              </span>{" "}
              <span>+961 12 345 678</span>
            </p>
          </div>
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{ padding: "10px !important", margin: 0 }}
        >
          <div style={{ margin: 50 }}>
            <img
              style={{ width: "100%" }}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/Pages/${image}`}
            />
          </div>
          {/* <div className={styles.row}>
            <img src={cuisine1} width={"25%"} />
            <img src={cuisine2} width={"25%"} />
            <img src={cuisine3} width={"25%"} />
          </div>
          <div className={styles.row}>
            <img src={cuisine4} width={"25%"} />
            <img src={cuisine5} width={"25%"} />
            <img src={cuisine6} width={"25%"} />
          </div>
          <div className={styles.row}>
            <img src={cuisine7} width={"25%"} />
            <img src={cuisine8} width={"25%"} />
            <img src={cuisine9} width={"25%"} />
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Cuisine;

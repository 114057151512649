import React from "react";

import styles from "../../styles/Home.module.css";
import logo2 from "../../images/icons/logo2.svg";
import Services from "./Services";
import Chalets from "./Chalets";
import Gallery from "./Gallery";
import Cuisine from "./Cuisine";
import Contact from "./Contact";
import useFetch from "../../customHooks/useFetch";
import { useMediaQuery } from "@mui/material";

const Home = () => {
  const isMobile = useMediaQuery("(max-width: 450px)");

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/page`,
    {
      name: "home",
    }
  );

  const customStyles = {
    heroSection: {
      backgroundImage: `url(${`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/Pages/${data?.image1}`})`,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: isMobile ? "" : "center",
      paddingTop: isMobile ? 100 : 0,
      alignItems: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    missionSection: {
      backgroundImage: `url(${`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/Pages/${data?.image2}`})`,
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  };

  const title_classes = [
    "hero_section_title_a",
    "hero_section_title_b",
    "hero_section_title_c",
  ];
  return (
    <div>
      <div style={customStyles.heroSection}>
        <img src={logo2} style={{ height: 230, marginBottom: 20 }} />
        {data?.title1?.split("/").map((title, index) => (
          <h1 key={index} className={styles[title_classes[index]]}>
            {title}
          </h1>
        ))}
      </div>
      <div style={customStyles.missionSection}>
        <div className={styles.missionContent}>
          <h1>{data?.title2}</h1>

          <div
            className={styles.missionParagraph}
            dangerouslySetInnerHTML={{ __html: data.paragraph2 }}
          />
        </div>
      </div>
      <Services />
      <Chalets />
      {data && data?.id && <Gallery id={data?.id} />}
      <Cuisine
        title={data?.title3}
        paragraph={data?.paragraph3}
        image={data?.image3}
      />

      <Contact image={data?.image4} />
    </div>
  );
};

export default Home;

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import SingleCustomAutocomplete from "../components/CustomAutocomplete/SingleCustomAutocomplete";
import { CustomDate } from "../components/Inputs/CustomInputForms";
import Img from "../images/home.png";
import people from "../images/icons/people.svg";
import view from "../images/icons/view.svg";
import date from "../images/icons/date.svg";
import budget from "../images/icons/budget.svg";
import styles from "../styles/Rooms.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import RoomCard from "../components/RoomCard";

const Rooms = () => {
  const [filterForm, setFilterForm] = useState({
    from: null,
    to: null,
    people: null,
    budget: null,
    view: null,
  });

  const [budgets, setBudgets] = useState([
    { id: 1, name: "50 $", value: 50 },
    { id: 2, name: "100 $", value: 100 },
    { id: 3, name: "150 $", value: 150 },
    { id: 4, name: "200 $", value: 200 },
    { id: 5, name: "250 $", value: 250 },
    { id: 6, name: "300 $", value: 300 },
    { id: 7, name: "350 $", value: 350 },
    { id: 8, name: "400 $", value: 400 },
    { id: 9, name: "450 $", value: 450 },
    { id: 10, name: "500 $", value: 500 },
  ]);

  const customOnChange = (newValue, name) => {
    setFilterForm((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target;

    setFilterForm({
      ...filterForm,
      [id]: value,
    });
  };

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    const params = {
      from: filterForm.from,
      to: filterForm.to,
      people: filterForm.people,
      budget:
        budgets.find((budget) => budget.id == filterForm.budget)?.value ?? 0,
      view: filterForm.view,
    };

    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/rooms`, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);

  const customStyle = {
    backgroundImage: `url(${Img}) `,
    width: "100vw",
    height: "100%",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div>
      <div style={customStyle}>
        <h1 className={styles.bigTitle}>ROOMS</h1>
        <img src={Img} style={{ visibility: "hidden", width: "100vw" }} />
      </div>

      <Grid
        container
        style={{ padding: 20, marginBottom: 100, alignItems: "center" }}
      >
        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          <CustomDate
            iconSrc={date}
            label={"From"}
            id={"from"}
            value={filterForm.from}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          <CustomDate
            iconSrc={date}
            label={"To"}
            id={"to"}
            value={filterForm.to}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          <SingleCustomAutocomplete
            iconSrc={people}
            filedName="people"
            label={"people"}
            list={[
              { id: 1, name: "1 person" },
              { id: 2, name: "2 people" },
              { id: 3, name: "3 people" },
              { id: 4, name: "4 people" },
              { id: 5, name: "5 people" },
              { id: 6, name: "6 people" },
              { id: 7, name: "7 people" },
              { id: 8, name: "8 people" },
              { id: 9, name: "9 people" },
              { id: 10, name: "10 people" },
            ]}
            value={filterForm.people}
            listKey="id"
            description="name"
            customOnChange={customOnChange}
          />
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          <SingleCustomAutocomplete
            iconSrc={budget}
            filedName="budget"
            label={"budget"}
            list={budgets}
            value={filterForm.budget}
            listKey="id"
            description="name"
            customOnChange={customOnChange}
          />
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          <SingleCustomAutocomplete
            iconSrc={view}
            filedName="view"
            label={"view"}
            list={[
              { id: 1, name: "sea view" },
              { id: 2, name: "mountain view" },
            ]}
            value={filterForm.view}
            listKey="id"
            description="name"
            customOnChange={customOnChange}
          />
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          style={{ padding: "1rem", maxWidth: "500px", margin: "auto" }}
        >
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <button onClick={getData} className={styles.submit}>
              APPLY
            </button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        style={{ paddingLeft: 20, paddingRight: 40, paddingBottom: 100 }}
        spacing={5}
      >
        {data?.map((room, index) => (
          <RoomCard key={index} room={room} filterForm={filterForm} />
        ))}
      </Grid>
    </div>
  );
};

export default Rooms;

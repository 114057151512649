import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import FormControl from '@material-ui/core/FormControl';
import { FormControl } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import arrow from "../../images/icons/arrow.svg";

import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputAdornment-positionStart": {
      // marginLeft: "10%",
    },
    "& .MuiAutocomplete-endAdornment": {
      // marginRight: "10%",
    },

    "& fieldset": {
      "& legend": {
        display: "none",
      },
    },
    "& . MuiFormLabel-filled": {
      color: "black",
    },

    "& label.Mui-focused": {
      color: "rgba(1, 116, 207, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(1, 116, 207, 1)",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "0px !important",

      "& fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
    },

    "& .MuiInputLabel-root": {
      color: "#193163",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "20px",
      textTransform: "uppercase",
      right: 0,
      textAlign: "center",
    },

    "& .MuiInputLabel-root.Mui-focused ": {
      textAlign: "left",
      marginBottom: 20,
      transform: "translate(0px, -30px)  ",
      margin: "auto",
    },

    "& .MuiInputLabel-shrink": {
      transform: "none",
      marginTop: "15px",
      zIndex: -1,
    },

    "& .MuiFormLabel-filled": {
      textAlign: "left",
      transform: "translate(0px, -45px)  ",
      zIndex: -1,
    },
    // focused: {
    //   border: 0,
    // },

    "& .MuiInputBase-root": {},

    // "& label": {
    //   paddingLeft: "45px !important",

    // },

    // "& .MuiAutocomplete-endAdornment": {
    //   "& .MuiAutocomplete-popupIndicator": {
    //     "& svg": {
    //       display: "none",
    //     },
    //   },
    // },
  },

  // clearIndicator: {
  //   backgroundColor: "gray ",
  //   "& span": {
  //     "& svg": {
  //       "& path": {
  //         d: "path('M20 1L10.5 6L1 1') ", // your svg icon path here
  //       },
  //     },
  //   },
  // },
  // popupIndicator: {
  //   backgroundColor: "blue ",
  //   "& span": {
  //     "& svg": {
  //       "& path": {
  //         d: "path('M20 1L10.5 6L1 1') !important", // your svg icon path here
  //       },
  //     },
  //   },
  // },

  // endAdornment: { paddingRight: "32px !important" },
  // popupIndicator: {
  //   background: `url(${img})`,
  //   width: 20,
  //   height: 20,
  //   backgroundRepeat: "no-repeat",
  // },

  // clearIndicator: {
  //   position: "absolute !important",
  //   right: "0px !important",
  // },

  inputRoot: {
    "& input": {
      color: "rgba(25, 49, 99, 1)",
      fontFamily: "League Spartan",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      //center
      textAlign: "left",
      textTransform: "uppercase",
      margin: "auto",
    },
    // "& .MuiInputBase-formControl": {
    //   textAlign: "center",
    // },
  },

  groupLabel: { color: "red" },
}));

const SingleCustomAutocomplete = (props) => {
  const classes = useStyles();

  const filedName = props.filedName;
  const fieldLabel = props.label;
  const list = props.list;
  const fieldValue = props.value;
  const listKey = props.listKey;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;
  const loading = props.loading || null;

  const optionForLabel = (label) => {
    if (typeof label === "object" && !Array.isArray(label) && label !== null) {
      return label[listDescription];
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label;
    });
    if (toReturn) {
      return toReturn[listDescription];
    }
    return `${label}`;
  };

  const getOptionSelected = (option, value) => {
    if (
      typeof option === "object" &&
      !Array.isArray(option) &&
      option !== null
    ) {
      return option[listKey] == value;
    }
    return false;
  };

  const update = (event, value) => {
    if (loading) {
      customOnChange();
      return;
    }
    if (value === null) {
      customOnChange(null, filedName, event);
      return;
      // }else if( typeof value === 'string'){
      //     setValue(optionForLabel(value))
    } else {
      customOnChange(value[listKey], filedName, value[listDescription], event);
    }
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Autocomplete
        popupIcon={<img src={arrow} />}
        classes={classes}
        loading={loading}
        disabled={props.disabled ? props.disabled : false}
        name={filedName}
        options={list} // Options List
        value={fieldValue}
        onChange={update}
        getOptionLabel={optionForLabel}
        getOptionSelected={getOptionSelected}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={fieldLabel}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img src={props.iconSrc} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

SingleCustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  value: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
  loading: PropTypes.any,
};

export default SingleCustomAutocomplete;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Img from "../images/home.png";
import { useLocation, Link } from "react-router-dom";
import styles from "../styles/Book.module.css";
import axios from "axios";
import { useForm } from "react-hook-form";
const Book = () => {
  const { state } = useLocation();
  const [nights, setNights] = useState(0);
  const [room, setRoom] = useState({});
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const getRoom = () => {
    const params = {
      id: state.id,
    };

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/room`, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        setRoom(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [dates, setDates] = useState(null);

  const getDaysArray = (s, e) => {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d).getDate());
    }
    return a;
  };

  useEffect(() => {
    const date1 = state?.from;
    const date2 = state?.to;
    if (!date1 || !date2) return;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setDates((prev) => ({
      ...prev,
      month1: monthNames[date1.getMonth()],
      month2: monthNames[date2.getMonth()],
      days: getDaysArray(date1, date2),
      year: date1.getFullYear(),
    }));

    setNights(diffDays);
    getRoom();
  }, []);

  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adults: 0,
      kids: 0,
      ext: "+961",
      card_number: "",
      expiration_date: "",
      cvc: "",
      from_date: state?.from,
      to_date: state?.to,
      room_id: state?.id,
    },
  });
  const onSubmit = (data) => {
    if (step == 1) {
      setStep(2);
      return;
    } else {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/user/book`, data)
        .then((res) => {
          setStep(3);
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formatCardNum = (v) => {
    const x = v
      .replace(" ", "")
      .split(/(\d{4})/)
      .filter((w) => w.length > 0)
      .join(" ");
    setValue("card_number", x);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // formatCardNum(value["card_number"]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const customStyle = {
    backgroundImage: `url(${Img}) `,
    width: "100vw",
    height: "100%",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };

  function cc_format(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    setValue("card_number", parts.length > 1 ? parts.join(" ") : value);
  }

  function exd_format(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 4);
    const parts = [];

    for (let i = 0; i < v.length; i += 2) {
      parts.push(v.substr(i, 2));
    }

    setValue("expiration_date", parts.length > 1 ? parts.join("/") : value);
  }

  return (
    <div>
      {/* <img src={Img} /> */}

      <div style={customStyle}>
        <h1 className={styles.bigTitle}>BOOK ROOM</h1>
        <img src={Img} style={{ visibility: "hidden", width: "100vw" }} />
      </div>

      {loading && <div className="loading">Loading&#8230;</div>}

      <Grid container spacing={10} style={{ paddingBottom: 100 }}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {step == 1 ? (
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <input
                placeholder="FULL NAME"
                className={styles.input}
                style={{ borderColor: errors.full_name && "red" }}
                {...register("full_name", { required: true })}
              />

              <input
                placeholder="EMAIL ADDRESS"
                className={styles.input}
                style={{ borderColor: errors.email_address && "red" }}
                {...register("email_address", { required: true })}
              />

              <div className={styles.responsiveInLine}>
                <input
                  placeholder="PHONE EXT"
                  className={styles.input}
                  style={{ width: "20%", borderColor: errors.ext && "red" }}
                  {...register("ext", { required: true })}
                />

                <input
                  placeholder="PHONE NUMBER"
                  className={styles.input}
                  style={{ width: "80%", borderColor: errors.phone && "red" }}
                  {...register("phone", { required: true })}
                />
              </div>

              <div className={styles.counter}>
                <label>NUMBER OF ADULTS</label>
                <div className={styles.row}>
                  <span
                    onClick={() => setValue("adults", getValues("adults") - 1)}
                  >
                    -
                  </span>
                  <input
                    placeholder=""
                    className={styles.input}
                    style={{
                      borderColor: errors.adults && "red",
                      width: "20%",
                    }}
                    {...register(
                      "adults",
                      {
                        valueAsNumber: true,
                      },
                      { required: true }
                    )}
                  />
                  <span
                    onClick={() => setValue("adults", getValues("adults") + 1)}
                  >
                    +
                  </span>
                </div>
              </div>

              <div className={styles.counter}>
                <label>NUMBER OF KIDS</label>
                <div className={styles.row}>
                  <span onClick={() => setValue("kids", getValues("kids") - 1)}>
                    -
                  </span>
                  <input
                    placeholder=""
                    className={styles.input}
                    style={{ borderColor: errors.kids && "red", width: "20%" }}
                    {...register(
                      "kids",
                      {
                        valueAsNumber: true,
                      },
                      { required: true }
                    )}
                  />
                  <span onClick={() => setValue("kids", getValues("kids") + 1)}>
                    +
                  </span>
                </div>
              </div>
              <div className={styles.formFooter}>
                <p>
                  {room.price} <span>USD</span>
                </p>
                <input
                  className={styles.submitButton}
                  type="submit"
                  value="proceed to payment"
                />
              </div>
            </form>
          ) : step == 2 ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.form}
              style={{ paddingTop: 70, paddingBottom: 70 }}
            >
              <input
                placeholder="cardholder name"
                className={styles.input}
                style={{ borderColor: errors.cardholder_name && "red" }}
                {...register("cardholder_name", { required: true })}
              />

              <input
                // type="number"
                placeholder="card number"
                className={styles.input}
                style={{ borderColor: errors.card_number && "red" }}
                {...register("card_number", {
                  required: true,
                  // minLength: 16,
                  // maxLength: 16,
                  // length: 16,

                  onChange: (e) => cc_format(e.target.value),
                })}
              />

              <div className={styles.formFooterPayment}>
                <input
                  placeholder="expiration"
                  className={styles.input}
                  style={{
                    width: "40%",
                    borderColor: errors.expiration_date && "red",
                  }}
                  {...register("expiration_date", {
                    required: true,
                    onChange: (e) => exd_format(e.target.value),
                  })}
                />

                <input
                  // type="number"
                  placeholder="CVC"
                  className={styles.input}
                  style={{
                    width: "20%",
                    borderColor: errors.cvc && "red",
                  }}
                  {...register("cvc", {
                    valueAsNumber: true,
                    required: true,
                    minLength: 3,
                    maxLength: 3,
                    length: 3,
                  })}
                />

                <input
                  style={{ width: "40%" }}
                  className={styles.submitButton}
                  type="submit"
                  value="CHECKOUT"
                />
              </div>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h1>ROOM BOOKED SUCCESSFULLY</h1>
              <h2>THANK YOU</h2>
            </div>
          )}
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className={styles.roomCard}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${room?.image}`}
              alt="image"
              width={"100%"}
              // className={styles.img}
            />
            <h1 style={{ fontSize: "32px" }}>{room?.name}</h1>
            {/* <h2>
              From {state.from.toISOString().substring(0, 10)} to{" "}
              {state.to.toISOString().substring(0, 10)}
            </h2> */}
            <h2>{room?.description}</h2>

            {/* <h3>
              {room?.price} USD X {nights}
            </h3>
            <strong>TOTAL</strong>
            <h3>{room?.price * nights} USD</h3> */}
            <h3>
              {dates?.month1}{" "}
              {dates?.days?.map((day, i, arr) => (
                <React.Fragment key={i}>
                  {day > arr[i - 1] ? "" : i != 0 ? ` & ${dates?.month2}` : ""}

                  <span>
                    {i != 0 && day > arr[i - 1] ? "-" : ""} {day}
                  </span>
                </React.Fragment>
              ))}
              <span>, {dates?.year}</span>
            </h3>

            <h3>{}</h3>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Book;

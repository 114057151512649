import React, { useState } from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import arrow from "../../images/icons/arrow.svg";
import { InputAdornment } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputAdornment-positionStart": {
      // marginLeft: "10%",
    },
    "& .MuiInputAdornment-positionEnd": {
      // marginRight: "10%",
    },

    "MuiInputAdornment-root:": {
      // marginRight: "10%",
    },

    "& fieldset": {
      "& legend": {
        display: "none",
      },
    },
    "& . MuiFormLabel-filled": {
      color: "black",
    },

    "& label.Mui-focused": {
      color: "rgba(1, 116, 207, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(1, 116, 207, 1)",
    },
    "& .MuiOutlinedInput-root": {
      // paddingRight: "0px !important",
      "& fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(1, 116, 207, 1)",
        borderRadius: 0,
      },
    },

    "& .MuiInputLabel-root": {
      color: "#193163",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "20px",
      textTransform: "uppercase",
      right: 0,
      textAlign: "center",
    },

    "& .MuiInputLabel-root.Mui-focused ": {
      textAlign: "left",
      marginBottom: 20,
      transform: "translate(0px, -30px)  ",
      margin: "auto",
    },

    "& .MuiInputLabel-shrink": {
      transform: "none",
      marginTop: "15px",
      zIndex: -1,
    },

    "& .MuiFormLabel-filled": {
      textAlign: "left",
      transform: "translate(0px, -45px)  ",
      zIndex: -1,
    },
    // focused: {
    //   border: 0,
    // },

    "& .MuiInputBase-root": {},

    // "& label": {
    //   paddingLeft: "45px !important",

    // },

    // "& .MuiAutocomplete-endAdornment": {
    //   "& .MuiAutocomplete-popupIndicator": {
    //     "& svg": {
    //       display: "none",
    //     },
    //   },
    // },
  },

  inputRoot: {
    "& input": {
      color: "rgba(25, 49, 99, 1)",
      fontFamily: "League Spartan",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      //center
      textAlign: "left",
      textTransform: "uppercase",
      margin: "auto",
    },
    // "& .MuiInputBase-formControl": {
    //   textAlign: "center",
    // },
  },

  // clearIndicator: {
  //   backgroundColor: "gray ",
  //   "& span": {
  //     "& svg": {
  //       "& path": {
  //         d: "path('M20 1L10.5 6L1 1') ", // your svg icon path here
  //       },
  //     },
  //   },
  // },
  // popupIndicator: {
  //   backgroundColor: "blue ",
  //   "& span": {
  //     "& svg": {
  //       "& path": {
  //         d: "path('M20 1L10.5 6L1 1') !important", // your svg icon path here
  //       },
  //     },
  //   },
  // },

  // endAdornment: { paddingRight: "32px !important" },
  // popupIndicator: {
  //   background: `url(${img})`,
  //   width: 20,
  //   height: 20,
  //   backgroundRepeat: "no-repeat",
  // },

  // clearIndicator: {
  //   position: "absolute !important",
  //   right: "0px !important",
  // },
}));

export const CustomDate = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          minDate={new Date()}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          components={{
            OpenPickerIcon: () => <img src={arrow} />,
          }}
          className={[classes.root, classes.inputRoot]}
          label={props.label}
          inputFormat={props.value ? "DD/MM/YYYY" : ""}
          value={props.value}
          onChange={(val) => {
            props.onChange({ target: { id: props.id, value: new Date(val) } });
          }}
          id={props.id}
          renderInput={(params) => (
            <TextField
              onClick={(e) => setOpen(true)}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={props.iconSrc} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

CustomDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired;

import React, { useState } from "react";
import styles from "../../styles/Home.module.css";
import { Container, Grid } from "@mui/material";

import useFetch from "../../customHooks/useFetch";

const Gallery = ({ id }) => {
  const [selected, setSelected] = useState(null);

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/images`,
    {
      table_name: "pages",
      row_id: id,
    }
  );

  return (
    // <Container sx={{ maxWidth: "90vw" }}>
    <Grid container className={styles.gallerySection}>
      <Grid
        item
        xl={2}
        lg={2}
        md={2}
        sm={12}
        xs={12}
        sx={{ padding: "10px !important", margin: 0 }}
      >
        <div className={styles.imgsGrid}>
          {data.map((image, index) => (
            <img
              key={index}
              onClick={() => setSelected(image)}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${image.name}`}
              className={`${styles.gridImg}
                ${
                  !selected && index == 0
                    ? styles.selectedGalleryImg
                    : selected?.id == image?.id
                    ? styles.selectedGalleryImg
                    : null
                }
              `}
            />
          ))}
        </div>
      </Grid>

      <Grid
        item
        xl={10}
        lg={10}
        md={10}
        sm={12}
        xs={12}
        sx={{ padding: "10px !important", margin: 0 }}
      >
        <img
          src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${
            selected ? selected?.name : data[0]?.name
          }`}
          className={styles.galleryBigImg}
        />
      </Grid>
    </Grid>
    // </Container>
  );
};

export default Gallery;
